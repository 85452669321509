/* ------------------------------------ *\
   #METADATA-DYNAMIC-ACCORDION
\* ------------------------------------ */

.mr-resource__metadata-dynamic {
    padding-top: 0 !important;

    .mr-content-block {
        display: block !important;
    }
}